<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3"
        text
        v-on="on"
        small
      >
        Logos
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Customize The Logos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <v-card-text>
        <v-list>
          <template v-for="(l, i) in iLogos">
            <v-list-item :key="`${i}_l`">
              <v-list-item-content>
                  <v-img
                    :src="l.url"
                    width="192"
                    height="64"
                    contain
                  ></v-img>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  color="error"
                  small fab
                  @click.stop="deleteLogo(i)"
                >
                  <v-icon>fas fa-trash</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="i + 1 < logos.length"
              :key="`${i}_d`"
            ></v-divider>
          </template>
        </v-list>
        <v-btn
          color="color3"
          text small
          @click.stop="showAdd = true"
          v-if="!loading"
        >
          Add A Logo
        </v-btn>
        <v-expand-transition>
          <image-library
            v-if="showAdd"
            :username="username"
            ref="library"
            noPreview
            @preview-click="onPreviewClick"
            singleRow
            :imgWidth="192"
            :imgHeight="64"
            :disabled="loading"
          />
        </v-expand-transition>
      </v-card-text>
      <v-card-actions>
       <v-spacer></v-spacer>
       <v-btn
        color="success"
        small fab
        :disabled="!dirty"
        :loading="loading"
        @click.stop="saveLogos"
      >
        <v-icon>fas fa-save</v-icon>
       </v-btn>
       <v-btn
        color="error"
        small fab
        :disabled="loading"
        @click.stop="dialog = false"
      >
        <v-icon>fas fa-times</v-icon>
       </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import TournamentSummary from '@/classes/TournamentSummary'
import ImageLibrary from '@/components/Organization/OrganizationImageLibrary.vue'

export default {
  data () {
    return {
      dialog: false,
      loading: false,
      summary: null,
      summaryOg: null,
      isDefault: true,
      showAdd: false,
      noOrg: false,
      iLogos: []
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    logos () {
      return this.summary && this.summary.logos
    },
    dto () {
      const dto = [...this.iLogos]
      this.noOrg && dto.push('noOrg')
      return dto
    },
    noOrg2 () {
      return this.logos && this.logos.includes('noOrg')
    },
    username () {
      return this.tournament.organization.username
    },
    dirty () {
      return this.summary && JSON.stringify(this.summary.logos) !== JSON.stringify(this.iLogos)
    }
  },
  methods: {
    getLogos () {
      this.loading = true
      this.$VBL.tournament.logos.get(this.tournament.id)
        .then(r => {
          this.summary = new TournamentSummary(r.data.summary)
          this.iLogos = JSON.parse(JSON.stringify(this.summary.logos))
          this.isDefault = r.data.isDefault
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    saveLogos () {
      this.loading = true
      this.$VBL.tournament.logos.post(this.tournament.id, this.dto)
        .then(r => {
          this.dialog = false
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    deleteLogo (i) {
      const d = this.iLogos.splice(i, 1)
      if (d[0].raw === 'org') this.noOrg = true
      console.log(d)
    },
    onPreviewClick (img) {
      this.iLogos.push(img)
      this.showAdd = false
    }
  },
  watch: {
    dialog: function (val) {
      val && this.getLogos()
    }
  },
  components: {
    ImageLibrary
  }
}
</script>
